export const LocalSessionKey = {
  ACCESS_TOKEN: 'ACCESS_TOKEN',
  USER_ID: 'USER_ID',
  OUTLET_ID: 'OUTLET_ID',
  MOBILE_NO: 'MOBILE_NO',
  LAT_LNG: 'LAT_LNG',
  USER_DETAIL: 'USER_DETAIL',
  USER_DATA: 'USER_DATA',
  RECENT_SEARCH: 'RECENT_SEARCH',
  ORDER_TYPE: 'ORDER_TYPE',
  PLATFORM: 'PLATFORM',
  NAME: 'NAME',
  RESTAURANT_DETAIL: 'RESTAURANT_DETAIL',
  APP_VERSION: 'APP_VERSION',
  ENCRYPTION_KEY: 'ENCRYPTION_KEY',
  TOKEN: 'TOKEN',
  REFRESH_TOKEN: 'REFRESH_TOKEN',
  MAX_EMP_DISC_VALUE: 'MAX_EMP_DISC_VALUE',
  DEVICE_NAME: 'DEVICE_NAME',
  OS_VERSION: 'OS_VERSION',
  DEVICE_BRAND: 'DEVICE_BRAND',
  OS_NAME: 'OS_NAME',
  DEVICE_MODEL: 'DEVICE_MODEL'
};
