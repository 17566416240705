/* eslint-disable no-undef */
import './index.scss';

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

// import { number } from 'prop-types';
import LoginNumber from '../../components/login-mobile-number';
import LoginOtp from '../../components/login-otp';
import LoginOrder from '../../components/login-order-for';
import LoginSuccess from '../../components/login-success';
import Loader from '../../components/loader-backdrop';
import Modal from '../../containers/modal';
import { ROUTES as _routes } from '../../constants/app-constants';
import {
  getSignup,
  getVerifyOtp,
  getUpdateUser,
  getUserDetail,
  signUpWithEmail,
  generatePhoneOtp
} from '../../burger-king-lib/redux/login/actions';
// import { getToken } from '../../burger-king-lib/redux/home/actions';
import { getCartItem } from '../../burger-king-lib/redux/cart/actions';
import { getCloseIcon, getLeftArrowImage } from '../../utils/imageChanges';
// import { SessionStorage } from '../../burger-king-lib/utils/storage';
import { LocalSessionKey } from '../../burger-king-lib/constants/app-constants';
import { getCartItemPayload } from '../../payload-handling/cart';
import {
  getSignUpPayload,
  getOtpPayload,
  getUserUpdatePayload,
  getEmailPayload,
  getGenerateOtpPayload
} from '../../payload-handling/login';
import { Analytics } from '../../helpers/analytics';
// import SocialLoginContainer from '../../containers/social-login';
import { LocalStorage } from '../../burger-king-lib/utils/storage.web';

class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentProgress: 'mobileNumber',
      mobileNumberBtnTxt: 'Enter 10 digit phone number',
      mobileNumber: '',
      showBackdropLoader: false,
      verifyOtpError: '',
      otpError: false,
      showSocialLogin: true,
      email: '',
      facebookData: {},
      orderName: '',
      loginType: ''
    };
  }

  componentDidUpdate(prevProps) {
    const { loginData, homeData } = this.props;
    if (loginData && loginData.signup !== prevProps.loginData.signup) {
      if (loginData.signup.error === 0) {
        this.setSignUpSuccesshandler();
      } else if (loginData.signup.error) {
        alert(loginData.signup.message);
      }
      if (loginData.signup.message) this.hideBackDropLoader();
    }

    if (
      loginData &&
      loginData.signInWithEmail !== prevProps.loginData.signInWithEmail
    ) {
      if (loginData.signInWithEmail.error === 0) {
        this.verifyEmailUser(loginData.signInWithEmail.content);
      }
      if (loginData.signInWithEmail.error) {
        this.setOtpError(loginData.signInWithEmail.message);
      }
      if (loginData.signInWithEmail.message) this.hideBackDropLoader();
    }

    if (loginData && loginData.otp !== prevProps.loginData.otp) {
      if (loginData.otp.error === 0) {
        // this.onSignupVerify();
        this.setSignUpSuccesshandler();
      }
      if (loginData.otp.error) {
        this.setOtpError(loginData.otp.message);
      }
      if (loginData.otp.message) this.hideBackDropLoader();
    }

    if (loginData && loginData.verifyOtp !== prevProps.loginData.verifyOtp) {
      if (loginData.verifyOtp.error === 0) {
        this.setVerifyOtpSuccessHandler(loginData.verifyOtp.content);
      }
      if (loginData.verifyOtp.error) {
        this.setOtpError(loginData.verifyOtp.message);
      }
      if (loginData.verifyOtp.message) this.hideBackDropLoader();
    }

    if (loginData && loginData.updateUser !== prevProps.loginData.updateUser) {
      if (loginData.updateUser.error === 0) {
        this.updateUserHandler();
        this.getUserDetailData();
      } else if (loginData.updateUser.error) {
        alert(loginData.updateUser.message);
      }
      if (loginData.updateUser.message) this.hideBackDropLoader();
    }

    if (homeData && homeData.data !== prevProps.homeData.data) {
      if (homeData && homeData.data && homeData.data.content) {
        LocalStorage.set(
          LocalSessionKey.USER_ID,
          homeData.data.content.user_id
        );
        LocalStorage.set(LocalSessionKey.TOKEN, homeData.data.content.token);
        LocalStorage.set(
          LocalSessionKey.REFRESH_TOKEN,
          homeData.data.content.refresh_token
        );
        const {
          getUserDetailData,
          getCartItemData,
          tokenSuccessHandler
        } = this;
        // getUserDetailData();
        // getCartItemData();
        tokenSuccessHandler();
      }
    }
  }

  getUserDetailData = () => {
    const { getUserDetailData } = this.props;
    getUserDetailData();
  };

  // getTokenData = () => {
  //   const { getTokenData } = this.props;
  //   getTokenData();
  // };

  isNewUser = data => {
    const { is_new_user } = data;
    if (data && is_new_user) {
      // return true;
      this.setState({ isNewuser: true });
    }
    this.setState({ isNewuser: false });
  };

  setSignUpSuccesshandler = () => {
    this.setState({
      currentProgress: 'otp',
      mobileNumberBtnTxt: 'verify otp'
    });
  };

  verifyEmailUser = data => {
    const { is_new_user } = data;
    if (is_new_user) {
      this.setState({ showSocialLogin: false });
    } else {
      this.setVerifyOtpSuccessHandler(data);
    }
  };

  onSignupVerify = () => {
    const { mobileNumber } = this.state;
    const { getSignUpData } = this.props;
    const payload = getSignUpPayload(mobileNumber);
    getSignUpData(payload);
  };

  setVerifyOtpSuccessHandler = async data => {
    const {
      user_id,
      phone_no,
      name,
      bk_crowns,
      is_new_user,
      token,
      refresh_token
    } = data;
    const { email, loginType, showSocialLogin, isNewUser } = this.state;
    LocalStorage.set(LocalSessionKey.USER_ID, user_id);
    LocalStorage.set(LocalSessionKey.USER_DATA, phone_no);
    LocalStorage.set(LocalSessionKey.TOKEN, token);
    LocalStorage.set(LocalSessionKey.REFRESH_TOKEN, refresh_token);
    LocalStorage.set(LocalSessionKey.NAME, name);
    LocalStorage.set('crowns', bk_crowns);
    // this.getTokenData();
    this.updateUserData(data);
    this.isNewUser(data);
    if (data && data?.is_new_user) {
      const script = document.createElement('script');
      script.src = '//cdnt.netcoresmartech.com/smartechclient.js';
      script.onload = () => {
        smartech(
          'contact',
          6,
          { 'pk^mobile': phone_no, NAME: data.name },
          () => {
            smartech('identify', `${phone_no}`);
            smartech('dispatch', 'af_otpsubmit', { mobile: phone_no });
            smartech('dispatch', 'af_register', {
              NAME: data.name,
              mobile: phone_no
            });
          }
        );
      };
      document.head.appendChild(script);
      if (loginType === 'fb') {
        Analytics.logEventWithVariable(
          Analytics.Order.fbLogin,
          JSON.stringify({
            NAME: data.name,
            mobile: phone_no,
            user: 'new_user',
            email: data.email || email
          })
        );
      } else if (loginType === 'google') {
        Analytics.logEventWithVariable(
          Analytics.Order.googleLogin,
          JSON.stringify({
            NAME: data.name,
            mobile: phone_no,
            user: 'new_user',
            email: data.email || email
          })
        );
      } else {
        Analytics.logEventWithVariable(
          Analytics.Order.login,
          JSON.stringify({
            NAME: data.name,
            mobile: phone_no,
            user: 'new_user',
            email: data.email || email
          })
        );
      }
      const fbscript = document.createElement('script');
      fbscript.src = '//connect.facebook.net/en_US/fbevents.js';
      fbscript.onload = () => {
        fbq('track', 'CompleteRegistration', {
          NAME: data.name,
          mobile: phone_no,
          currency: 'INR',
          value: 0,
          email: data.email || email
        });
      };
      document.head.appendChild(fbscript);
    } else {
      const script = document.createElement('script');
      script.src = '//cdnt.netcoresmartech.com/smartechclient.js';
      script.onload = () => {
        smartech(
          'contact',
          6,
          {
            'pk^mobile': phone_no,
            NAME: data.name,
            mobile: phone_no,
            email: data.email
          },
          () => {
            smartech('identify', `${phone_no}`);
            smartech('dispatch', 'af_otpsubmit', { mobile: phone_no });
            smartech('dispatch', 'af_login', {
              NAME: data.name,
              mobile: phone_no,
              email: data.email
            });
          }
        );
      };
      document.head.appendChild(script);
      if (loginType === 'fb') {
        Analytics.logEventWithVariable(
          Analytics.Order.fbLogin,
          JSON.stringify({
            NAME: data.name,
            mobile: phone_no,
            email: data.email || email,
            user: 'existing_user'
          })
        );
      } else if (loginType === 'google') {
        Analytics.logEventWithVariable(
          Analytics.Order.googleLogin,
          JSON.stringify({
            NAME: data.name,
            mobile: phone_no,
            email: data.email || email,
            user: 'existing_user'
          })
        );
      } else {
        Analytics.logEventWithVariable(
          Analytics.Order.login,
          JSON.stringify({
            NAME: data.name,
            mobile: phone_no,
            email: data.email || email,
            user: 'existing_user'
          })
        );
      }
    }
    const fbscript = document.createElement('script');
    fbscript.src = '//connect.facebook.net/en_US/fbevents.js';
    fbscript.onload = () => {
      fbq('track', 'Contact', { name: data.name, mobile: phone_no });
    };
    document.head.appendChild(fbscript);
    this.setState({
      showBackdropLoader: false
    });
    this.updateUserHandler();
    this.getUserDetailData();
  };

  tokenSuccessHandler = () => {
    const { isNewUser } = this.state;
    if (isNewUser) {
      const { location } = this.props;
      if (location.search !== '?screen_type=login') {
        this.setState({
          currentProgress: 'orderFor',
          mobileNumberBtnTxt: 'done'
        });
      }
    } else {
      this.updateUserHandler();
    }
    this.setState({ verifyOtpError: '' });
  };

  getCartItemData = () => {
    // const { getCartItemData, theme } = this.props;
    // const payload = getCartItemPayload(theme);
    // getCartItemData(payload);
  };

  setOtpError = message => {
    this.setState({ verifyOtpError: message });
    if (message === 'Request new OTP') {
      this.setState({ verifyOtpError: message, otpError: true });
    }
  };

  hideBackDropLoader = () => {
    this.setState({ showBackdropLoader: false });
  };

  updateUserData = data => {
    const { loggedInToggleHandler } = this.props;
    const { phone_no } = data;
    LocalStorage.set(LocalSessionKey.USER_DATA, phone_no);
    this.setState({ showBackdropLoader: true });
    loggedInToggleHandler(data);
  };

  onLoginChangeHandler = text => {
    this.setState({ mobileNumberBtnTxt: text });
  };

  onGetOtpClickHandler = number => {
    const { getSignUpData } = this.props;
    const payload = getSignUpPayload(number);
    getSignUpData(payload);
    this.setState({ mobileNumber: number, showBackdropLoader: true });
  };

  onVerifyOtpClickHandler = value => {
    const { getOtpData } = this.props;
    const { mobileNumber } = this.state;

    this.setState({ showBackdropLoader: true });
    const payload = getOtpPayload(mobileNumber, value);
    getOtpData(payload);
  };

  onDoneClickHandler = name => {
    const { orderName } = this.state;
    let names = name;
    if (name === '' && orderName.length > 0) {
      names = orderName;
    }
    const { getUpdateUserData } = this.props;
    const payload = getUserUpdatePayload(names);
    getUpdateUserData(payload);
  };

  updateUserHandler = () => {
    const { onLoginSuccessHandler } = this.props;
    this.setState({ currentProgress: 'success' });
    setTimeout(() => onLoginSuccessHandler(), 1500);
  };

  onPrivacyPolicy = () => {
    const { history, onLoginSuccessHandler } = this.props;
    const { innerWidth } = window;
    if (innerWidth > 1023) {
      history.push(`${_routes.PRIVACY_POLICY_LINK}`);
    } else {
      history.push(`${_routes.PRIVACY_POLICY}`);
    }
    onLoginSuccessHandler();
  };

  onTermsPolicy = () => {
    const { history, onLoginSuccessHandler } = this.props;
    const { innerWidth } = window;
    if (innerWidth > 1023) {
      history.push(`${_routes.TERMS}`);
    } else {
      history.push(`${_routes.TERMS_AND_CONDITIONS}`);
    }
    onLoginSuccessHandler();
  };

  onChangeClickHandler = () => {
    this.setState({ currentProgress: 'mobileNumber' });
  };

  onFacebookLogin = data => {
    const { signUpWithEmailData } = this.props;
    this.setState({
      email: data.data.email,
      facebookData: data.data,
      orderName: data.data.name,
      loginType: 'fb'
    });
    const payload = getEmailPayload(data.data.email, data.data.accessToken);
    signUpWithEmailData(payload);
  };

  onGoogleLogin = data => {
    // console.log(data, 'data');
    const profile = data.profileObj;
    const { signUpWithEmailData } = this.props;
    this.setState({
      email: profile.email,
      facebookData: profile,
      orderName: profile.name,
      loginType: 'google'
    });
    const payload = getEmailPayload(profile.email, data.accessToken);
    signUpWithEmailData(payload);
  };

  resendOtpClickHandler = () => {
    const { mobileNumber } = this.state;
    const { onGetOtpClickHandler } = this;
    onGetOtpClickHandler(mobileNumber);
    alert('OTP has been sent');
    this.setState({ verifyOtpError: '', otpError: false });
  };

  onContentPolicy = () => {
    const { history, onLoginSuccessHandler } = this.props;
    const { innerWidth } = window;
    if (innerWidth > 1023) {
      history.push(`${_routes.PROMOTIONAL_PRIVACY_POLICY_LINK}`);
    } else {
      history.push(`${_routes.PPROMOTIONAL_PRIVACY_POLICY_LINK}`);
    }
    onLoginSuccessHandler();
  };

  generateData = number => {
    const { generatePhoneOtpData, loginData } = this.props;
    const { email } = this.state;
    const payload = getGenerateOtpPayload(email, number);
    generatePhoneOtpData(payload);
    this.setState({ mobileNumber: number, showBackdropLoader: true });
  };

  renderContent = () => {
    const {
      currentProgress,
      mobileNumberBtnTxt,
      mobileNumber,
      verifyOtpError,
      otpError,
      showSocialLogin,
      orderName,
      email
    } = this.state;
    const { theme } = this.props;
    let dom = null;
    switch (currentProgress) {
      case 'mobileNumber':
        dom = (
          <>
            <LoginNumber
              theme={theme}
              onClick={
                email !== '' && !showSocialLogin
                  ? this.generateData
                  : this.onGetOtpClickHandler
              }
              mobileNumberBtnTxt={mobileNumberBtnTxt}
              onChange={this.onLoginChangeHandler}
              onFacebookLogin={this.onFacebookLogin}
              responseGoogle={this.onGoogleLogin}
              showSocialLogin={showSocialLogin}
            />
          </>
        );
        break;
      case 'otp':
        dom = (
          <LoginOtp
            theme={theme}
            verifyOtpError={verifyOtpError}
            resendOtpClickHandler={this.resendOtpClickHandler}
            mobileNumber={mobileNumber}
            onVerifyOtpClick={this.onVerifyOtpClickHandler}
            onChangeClick={this.onChangeClickHandler}
            otpDisable={otpError}
            onFacebookLogin={this.onFacebookLogin}
            responseGoogle={this.onGoogleLogin}
            showSocialLogin={showSocialLogin}
          />
        );
        break;
      case 'orderFor':
        dom = (
          <LoginOrder
            theme={theme}
            onOrderClick={this.onDoneClickHandler}
            mobileNumberBtnTxt={mobileNumberBtnTxt}
            orderName={orderName}
          />
        );
        break;
      case 'success':
        dom = <LoginSuccess />;
        break;
      default:
        dom = '';
    }

    return dom;
  };

  onLoginClosehandler = () => {
    const { showSocialLogin, currentProgress } = this.state;
    const { onLoginSuccessHandler } = this.props;
    if (!showSocialLogin && currentProgress === 'mobileNumber') {
      LocalStorage.remove(LocalSessionKey.USER_ID);
      // this.getTokenData();
    }
    onLoginSuccessHandler();
  };

  render() {
    const { renderContent } = this;
    const { theme } = this.props;
    const { showBackdropLoader, currentProgress } = this.state;
    const { innerWidth } = window;

    return (
      <Modal
        isMobileFullLayout={innerWidth < 1023}
        hideClose
        contentDataCustomClass={`login-pg_content ${currentProgress ===
          'success' && 'success'}`}
        contentCustomClass={innerWidth >= 1023 && 'login-pg_data'}
        fullWidth
      >
        <div
          className={`login-pg_wrapper ${currentProgress === 'success' &&
            'success'}`}
        >
          <div
            className={`login-pg ${currentProgress === 'success' && 'success'}`}
          >
            {currentProgress !== 'success' && (
              <div className="login-pg_close">
                <img
                  src={getLeftArrowImage(theme)}
                  width="18"
                  height="18"
                  onClick={this.onLoginClosehandler}
                />
                {/* <div
                  className="login-pg_close_icon takeaway"
                  onClick={this.onLoginClosehandler}
                ></div> */}
              </div>
            )}
            {renderContent()}
            {currentProgress !== 'success' && (
              <div className="login-pg_footer">
                <p className="login-pg_footer_content">
                  By continuing, you agree for our{' '}
                  <u onClick={this.onTermsPolicy}>Terms & Conditions,</u>{' '}
                  <u onClick={this.onPrivacyPolicy}>Privacy Policy,</u> and{' '}
                  <u onClick={this.onContentPolicy}>Promotional T&C</u>
                </p>
              </div>
            )}
          </div>
          {showBackdropLoader ? <Loader theme={theme} /> : ''}
        </div>
      </Modal>
    );
  }
}

const mapStateToProps = state => {
  return {
    loginData: state.login,
    homeData: state.home
  };
};

const mapDispatchToProps = dispatch => {
  return {
    getSignUpData: payloadData => dispatch(getSignup(payloadData)),
    getOtpData: payloadData => dispatch(getVerifyOtp(payloadData)),
    getUpdateUserData: payloadData => dispatch(getUpdateUser(payloadData)),
    // getCartItemData: payloadData => dispatch(getCartItem(payloadData)),
    getUserDetailData: payloadData => dispatch(getUserDetail(payloadData)),
    // getTokenData: payloadData => dispatch(getToken(payloadData)),
    signUpWithEmailData: payloadData => dispatch(signUpWithEmail(payloadData)),
    generatePhoneOtpData: payloadData => dispatch(generatePhoneOtp(payloadData))
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Login));
