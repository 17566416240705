export const ROUTES = {
  HOME: '/',
  HOME2: '/home',
  PRODUCT_LISTING: '/product-listing',
  PRODUCT_DETAIL_POPUP: '/productAddonDetail-DL',
  PRODUCT_DETAIL: '/product-detail',
  SEARCH: '/search',
  ONBOARD: '/onboard',
  CART: '/cart',
  LOGIN: '/login',
  CHECKOUT_STATUS: '/checkout-statuses',
  ONLINE_PAYMENT: '/online-payment',
  ACCOUNT: '/account',
  RECENT_ORDERS: '/account/recent-orders',
  SAVED_ADDRESSES: '/account/saved-addressess',
  NOTIFICATIONS: '/account/notifications',
  SETTINGS: '/account/settings',
  SETTINGS2: '/settings',
  EDIT_PROFILE: '/account/profile',
  TRACK_ORDER: '/track-order',
  TERMS_AND_CONDITIONS: '/account/terms-conditions',
  TERMS: '/terms-and-conditions',
  PRIVACY_POLICY: '/account/privacy-policy',
  APPLY_MORE: '/apply-learn-more',
  NOTIFICATION_SETTINGS: '/account/notification-settings',
  HELP_SUPPORT: '/account/help-support',
  STORE_LOCATOR: '/store-locator',
  OFFERS: '/offers',
  MY_VOUCHERS: '/my-vouchers',
  BK_WALL: '/bk-wall',
  PRIVACY_POLICY_LINK: '/privacy-policy',
  PROMOTIONAL_PRIVACY_POLICY_LINK: '/promotional-terms',
  PPROMOTIONAL_PRIVACY_POLICY_LINK: '/account/promotional-terms',
  VIDEO_PLAYER: '/video-player',
  CREATING_BRIGHTER_FUTURES: '/creating-brighter-futures',
  TRUST_TASTE: '/Trust&Taste',
  CAREERS: '/careers',
  EDIT_PROFILEE: '/editProfile',
  FARM_TO_FORK: '/Farm-to-Fork',
  COVID_19: '/covid-19-safety',
  FRESH_TASTE: '/our-story',
  INVESTORS: '/investor-relations',
  HELP_SUPPORT_LINK: '/help-support',
  CONTACT_US: '/contact-us',
  CROWN_HISTORY: '/account/crown-history',
  FEEDBACK: '/feedback',
  PRIVACY_POLICY1_LINK: '/privacy-policy1',
  CONTACT_SHAREHOLDER: '/contact-for-shareholder-grievance-redressal',
  COMMITEE: '/board-committiees',
  BOARD_DIRECTORS: '/board-of-directors',
  FINANCIALS: '/financials',
  CORPORATE_ANNOUNCEMENTS: '/corporate-announcements',
  SHAREHOLDING_PATTERN: '/shareholding-pattern',
  POSTAL_BALLOT: '/postal-ballot',
  ANALYST_COVERAGE: '/analyst-coverage',
  CORPORATE_GOVERNANCE: '/corporate-governance',
  STAKEHOLDERS: '/stakeholder-grievance-redressal',
  ANALYST_MEETINGS: '/analyst-meetings',
  FRANCHISE: '/franchising',
  CUSTOMER_CARE: '/customer-care',
  INVESTOR_CATEGORY: '/category',
  INVESTOR_CATEGORY_QIP: '/QIP',
  INVESTOR_DOCUMENT: '/QIP/document',
  INVESTOR_SUBMIT: '/QIP/submit',
  INVESTOR_REJECTION: '/QIP/rejection-page',
  ORDER_STATUS: '/order-status',
  CART_OFFERS: '/cart-offers',
  ABOUT_US: '/about-us',
  PL: '/productListing-DL',
  JUSPAY_REDIRECT_URL: '/api/consumer/api/v1/juspay/juspayRedirectionPage',
  OFFER_ONBOARDING: '/offer-onboarding'
};
export const THEME = {
  THEMESTORAGE: 'theme',
  DELIVERY: 'delivery',
  RESTAURANT: 'takeaway',
  DINE_IN: 'dinein'
};
