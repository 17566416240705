import './index.scss';

import React, { Component } from 'react';
import PropTypes from 'prop-types';
// import BKlogo from '../../../../assets/images/bkLogo.png';
import BKLOGO from '../../../assets/images/bkLogo.png';
import InputLabelFloat from '../input-label-float';
import Button from '../button';

import { NameValidation } from '../../utils/validation';

class LoginOrder extends Component {
  constructor() {
    super();
    this.state = {
      currentFieldFocus: 'name',
      name: '',
      validation: {
        name: false
      }
    };
  }

  componentDidMount() {
    const { orderName } = this.props;
    if (orderName !== '') {
      this.setState({
        name: String(orderName),
        validation: {
          name: true
        }
      });
    }
    if (
      document &&
      document.getElementsByClassName('login-order__input') &&
      document.getElementsByClassName('login-order__input')[0]
    )
      document
        .getElementsByClassName('login-order__input')[0]
        .addEventListener('keyup', this.enterListener);
  }

  componentWillUnmount() {
    if (
      document &&
      document.getElementsByClassName('login-order__input') &&
      document.getElementsByClassName('login-order__input')[0]
    )
      document
        .getElementsByClassName('login-order__input')[0]
        .removeEventListener('keyup', this.enterListener);
  }

  enterListener = event => {
    event.preventDefault();
    if (event.keyCode === 13) {
      this.onClick();
    }
  };

  inputChangeHandler = (id, value) => {
    const val = value.trim();
    this.setState({ [id]: val });
  };

  validationChangeHandler = (id, value) => {
    const { validation } = this.state;
    const validationCopy = { ...validation };
    validationCopy[id] = value;
    this.setState({ validation: validationCopy });
  };

  validationOnClickHandler = () => {
    const { validation, name } = this.state;
    const { orderName } = this.props;
    const validationCopy = { ...validation };
    validationCopy.name = NameValidation(name || String(name));
    this.setState({ validation: validationCopy });
    return validation;
  };

  onClick = () => {
    const validation = this.validationOnClickHandler();
    if (
      Object.keys(validation).every(k => {
        if (!validation[k]) alert('enter name');
        return validation[k];
      })
    ) {
      const { onOrderClick } = this.props;
      const { name } = this.state;
      onOrderClick(name);
    }
  };

  inputOnFocus = id => {
    this.setState({ currentFieldFocus: id });
  };

  render() {
    const { name, currentFieldFocus } = this.state;
    const { mobileNumberBtnTxt, theme } = this.props;
    return (
      <div className="login-order">
        <img src={BKLOGO} width="70" height="70" />
        <p className="login-order__title">One last thing</p>
        <p className="login-order__description">What do we call you?</p>
        <div className="login-order__input">
          <InputLabelFloat
            theme={theme}
            id="name"
            type="text"
            label=""
            value={name}
            onChange={this.inputChangeHandler}
            currentFieldFocus={currentFieldFocus}
            onFocus={this.inputOnFocus}
            validationChange={this.validationChangeHandler}
            isName
          />
        </div>
        <div className="login-order__button">
          <Button
            theme={theme}
            content={mobileNumberBtnTxt}
            type="block"
            size="is-medium"
            onClick={this.onClick}
            disabled={!name || false}
            className="login-order__button_inactive"
          />
        </div>
        <p className="login-order__description">
          You can always change the name in your Order
        </p>
      </div>
    );
  }
}

LoginOrder.propTypes = {
  onOrderClick: PropTypes.func,
  mobileNumberBtnTxt: PropTypes.string
};

LoginOrder.defaultProps = {
  onOrderClick: () => {},
  mobileNumberBtnTxt: ''
};

export default LoginOrder;
